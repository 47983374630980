<template>
  <div>
    <div class="pc">
      <div class="container padding-top-60">
        <div class="subtitle2">구매를 완료하였습니다.</div>
        <div class="h7 main title-1">앱 마켓 팩/플러그인 구매</div>
        <div class="item">
          <div class="col-6">
            <div class="subtitle5 main">상품 <span class="body2 primary" v-if="order.order_product.is_subscription">(월결제)</span></div>
            <div class="body2 sub3">청구시작일 <span class="body2-medium sub2">{{ datesFormat(order.next_date, 'date_3') }}</span></div>
          </div>
          <div class="col-3 body2 sub">{{ order.order_product.product.name }}</div>
          <div class="col-3 body4 sub" style="text-align: right">
            <span class="body2-bold main">
              <span v-if="order.order_product.product.category2_name==='플랫폼 팩'">
                {{ order.order_product.product_price | currencyNum }}
              </span>
              <span v-else>{{ order.order_product.required_option_price|currencyNum }}</span>
              <span class="body4">
                <span class="sub">원
                  <span class="sub3" v-if="order.order_product.product.category2_name==='플랫폼 팩'">
                    /{{ order.order_product.date_unit }}개월
                  </span>
                  <span class="sub3" v-else-if="order.order_product.is_subscription">/월</span>
                </span>
              </span>
            </span>
          </div>
        </div>
        <div class="item" v-if="order.order_product.product.selectors.length > 1 && order.order_product.product.selectors[1].options[0].selected">
          <div class="col-6">
            <div class="subtitle5 main">케어서비스</div>
            <div class="body2 sub3">전화상담 후 충전금에서 차감</div>
          </div>
          <div class="col-3 body2 sub">전문가 확인 신청</div>
          <div class="col-3 body4 sub" style="text-align: right"><span class="body2-bold main">{{ order.order_product.add_option_price|currencyNum }}
            <span class="body4"><span class="sub">원<span class="sub3">/월</span></span></span></span></div>
        </div>
        <div class="item">
          <div class="col-6 subtitle4 main">총 결제금액</div>
          <div class="col-6" style="text-align:right">
            <div style="text-align: right"><span class="h6 main">{{ order.order_product.total_price|currencyNum }}
            <span class="body2-medium"><span class="sub">원<span class="sub3" v-if="order.order_product.is_subscription">/월</span></span></span></span></div>
          </div>
        </div>
        <div v-if="order.order_product.product.selectors.length > 1 && order.order_product.product.selectors[1].options[0].selected"
          class="box-info body3 sub2">영업일 2일 이내 케어 서비스 안내를 위해 전화 상담을 도와드릴 예정입니다.</div>
        <div class="flex-center">
          <div class="btns">
            <button class="button is-gray" @click="$router.push('order_list')">구매내역 보기</button>
            <button class="button is-primary" @click="clickSetting">구매한 기능 설정하기</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "MarketAccountDone",
    created() {

    },
    data() {
      return {}
    },
    methods: {
      clickSetting() {
        if(this.order.order_product.product.category2_name==='플러그인') {
          let key = this.order.order_product.product.params.find(i=> i.name === 'key').value_text;
          // 빌더로 이동할 플러그인 종류
          const addPluginList = ['point_coupon', 'partner_exhibition', 'partner_product_exhibition', 'recent_product'];
          if(addPluginList.indexOf(key)> -1) {
            if(this.$store.getters.admin.parent === 'builder') {
              window.parent.opener.postMessage({close: true, addPlugin: key}, '*');
            } else {
              let param = `add_plugin=${key}`;

              // 지정 경로로 이동시킬 플러그인
              const plugin_move_page = ['point_coupon'];
              if(plugin_move_page.indexOf(key)>-1) {
                param = 'front_path=my_point';
              }
              window.open(`https://${this.userService.key}.launchpack.co.kr/developers/ui/builder?${param}`, '_blank');
              window.parent.opener.postMessage({close: true}, '*');
            }
          } else {
            window.parent.opener.postMessage({close: true, adminRedirect: key}, '*');
          }
        } else {
          window.parent.opener.postMessage({close: true, adminRedirect: true}, '*');
        }
      }
    },
    computed: {
      order() {
        return this.$store.getters.basket;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .title-1
    border-top 1px solid $main
    padding 16px 0
    margin-top 40px
  .item
    border-top 1px solid $gray2
    padding 24px 0
    display flex
    align-items center
  .box-info
    padding 24px
    border-radius 8px
    background-color $gray4

  .btns
    margin 40px auto
    display grid
    grid-template-columns repeat(2, 242px)
    grid-column-gap 20px
</style>
